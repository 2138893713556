body[data-theme=dark] {
  .facet-row {
    & > .facet-name {
      color: var(--color-weight-800) !important;
    }
  }

  .radio-card {
    li,
    .content {
      color: white;
    }

    &.selected {
      li,
      .content {
        color: black;
      }
    }
  }

  .modal-body-wrapper .modal-body, .modal-body-wrapper .side-panel-body {
    color: var(--color-weight-800) !important;
  }
}

a.button-ghost {
  &:hover {
    color: var(--color-button-ghost-hover-text) !important;
  }

  clr-icon {
    color: inherit !important;
  }
}

.nav-link {
  a {
    clr-icon {
      color: inherit !important;
    }
  }
}


.table {
  td {
    vertical-align: middle;
  }
}

a.inline-link {
  color: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  text-decoration: none !important;

  &:hover {
    color: inherit !important;
    font-size: inherit !important;
    text-decoration: none !important;
  }
}

.color-gray,
.text-gray {
  color: var(--color-grey-300) !important;
}

table.tbl {
  color: var(--color-text-100);
  font-size: var(--font-size-sm);
  width: 100%;

  & > thead > tr {
    background: var(--color-card-bg);
  }

  & > tbody > tr {
    background: var(--color-card-bg);
  }

  td {
    text-align: left;
  }

  th, td {
    border-right: 1px solid var(--color-table-header-border);
    border-bottom: 1px solid var(--color-table-header-border);
    padding: 6px 12px;
    vertical-align: middle;
    font-size: var(--font-size-xs);

    &:first-child {
      border-left: 1px solid var(--color-table-header-border);
    }
  }

  thead tr:first-child {
    th, td {
      border-top: 1px solid var(--color-table-header-border);
    }
  }

  thead {
    td, th {
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
  }

  & > tbody {
    & > tr:hover {
      background: var(--color-table-row-hover-bg);

      .tbl > thead > tr {
        background: var(--color-table-row-hover-bg);
      }
    }
  }

  &.tbl-rounded {
    & > thead > tr:first-child {
      & > td:first-child, th:first-child {
        border-top-left-radius: 4px;
      }

      & > td:last-child, th:last-child {
        border-top-right-radius: 4px;
      }
    }

    & > tbody > tr:last-child {
      & > td:first-child {
        border-bottom-left-radius: 4px;
      }

      & > td:last-child {
        border-bottom-right-radius: 4px;
      }
    }
  }

  .tbl {
    width: calc(100% + 24px);
    margin: -6px -12px;

    td, th {
      font-size: 80%;
    }

    & > thead > tr > th:first-child,
    & > thead > tr > td:first-child {
      border-left: none;
    }

    & > thead > tr > th:last-child,
    & > thead > tr > td:last-child {
      border-right: none;
    }

    & > tbody > tr > td:first-child {
      border-left: none;
    }

    & > tbody > tr > td:last-child {
      border-right: none;
    }

    & > thead > tr:first-child > th,
    & > thead > tr:first-child > td {
      border-top: none;
    }

    & > tbody > tr:last-child > td {
      border-bottom: none;
    }
  }

  & > tbody > tr > td > .tbl-show-on-hover {
    opacity: 0.2;
  }

  & > tbody > tr:hover > td > .tbl-show-on-hover {
    opacity: 1;
  }
}

body[data-theme=dark] {
  .tbl {
    &.tbl-striped {
      & > tbody > tr:nth-child(even) {
        box-shadow: 0 0 0 100px inset rgba(0, 0, 0, 0.15);

        tr {
          box-shadow: 0 0 0 100px inset rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}

body[data-theme=default] {
  .tbl {
    &.tbl-striped {
      & > tbody > tr:nth-child(even) {
        box-shadow: 0 0 0 100px inset rgba(0, 0, 0, 0.03);

        tr {
          box-shadow: 0 0 0 100px inset rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
}

// tabs
.nav .nav-link.active:hover {
  background: none;
}